<script>
export default {
  name: 'InstructionsModal',
  data() {
    const rawItems = [
      ['${contract_number}', 'номер договора', '01APL22, 02MEG22'],
      ['${day}', 'дата создания договора', '"29", “22”'],
      ['${month}', 'месяц создания договора', 'декабрь, январь'],
      ['${year}', 'год создания договора', '2021 йил, 2022 йил'],
      [
        '${company_name}',
        'название компании',
        '“ISKANDAR MAX CONSTRUCTION”, “GREAT CITY”',
      ],
      ['${company_type}', 'тип компании(ООО и т.д)', 'ООО, МЧЖ'],
      ['${order_code}', 'номер приказа в договоре', '2-п, 5-п'],
      [
        '${director_full_name}',
        'полное имя директора',
        'Алиев Шухрат Шухратович, Худжакулов Хуршид Хуршидович',
      ],
      [
        '${director_short_name}',
        'краткое имя директора',
        'Алиев Ш.Ш, Худжакулов Х.Х',
      ],
      [
        '${manager_full_name}',
        'полное имя менеджера',
        'Насруллаев Шохрух Анварович, Алиева Феруза Кучкаровна',
      ],
      [
        '${manager_short_name}',
        'краткое имя менеджера',
        'Насруллаев Ш , Махмудова М',
      ],
      [
        '${client_full_name}',
        'полное имя клиента как в паспорте',
        'Nasrullaev Shokhrukh , Makhmudova Malokhat',
      ],
      [
        '${client_cyrillic_name}',
        'полное имя клиента на кириллице',
        'Алимова Дурдона Дилмуратовна, Мирзахмедов Шохрух Кахраманович',
      ],
      [
        '${client_cyrillic_short_name}',
        'краткое имя клиента на кириллице',
        'Алимова Д, Мирзахмедов Ш',
      ],
      [
        '${object_full_address}',
        'полное название объекта',
        'Юнусобод тумани 14-даҳа, 14-уй, Куйлюк тумани 12-даха, 24-уй',
      ],
      [
        '${apartments_list}',
        'список квартир на продажу для таблицы',
        'Таблица с инфо о квартире',
      ],
      ['${deadline_year}', 'год окончания строительства', '2022, 2023'],
      ['${deadline_quarter}', 'четверть окончания строительства', '1, 4'],
      ['${created_by}', 'кем создан', 'Алимова К, Шарипова Ж'],
      [
        '${price_per_square}',
        'cтоимость за каждый м^2',
        '7.100.000,00, 8.200.000,00',
      ],
      [
        '${price_per_square_text}',
        'cтоимость за каждый м^2 в текстовом формате',
        'етти миллион бир юз минг сўм 00 тийин, саккиз миллион икки юз минг сум 00 тийин',
      ],
      [
        '${apartment_price}',
        'стоимость квартиры',
        '418.900.000,00, 500.000.000,00',
      ],
      [
        '${apartment_price_text}',
        'стоимость квартиры в текстовом формате',
        'тўрт юз ўн саккиз миллион тўққиз юз минг сўм 00 тийин, беш юз миллион сум 00 тийин',
      ],
      [
        '${initial_payment}',
        'Первоначальный взнос',
        '209.450.000,00, 250.000.000,00',
      ],
      [
        '${initial_payment_text}',
        'Первоначальный взнос в текстовом формате',
        'икки юз тўққиз миллион тўрт юз эллик минг сўм 00 тийин, икки юз эллик миллион сум 00 тийин',
      ],
      [
        '${remaining_payment}',
        'остаток суммы для оплаты которую клиент платит по рассрочке',
        '100.000.000,00, 150.000.000,00',
      ],
      [
        '${remaining_payment_text}',
        'остаток суммы для оплаты которую клиент платит по рассрочке в текстовом формате',
        'юз миллион сум 00 тийин, юз эллик миллион сум 00 тийин',
      ],
      [
        '${bank_account}',
        'расчетный счет компании в банке',
        '20208000605151586001, 2020500030121245001',
      ],
      [
        '${bank_address}',
        'адрес банка компании',
        'ХАТВ “Orient Finans” бош офиси, “Kapitalbank” Юнусобод филлиали',
      ],
      ['${branch_turnover}', 'МФО банка', '01071, 02041'],
      ['${economic_classifier}', 'ОКЭД', '62010, 45011'],
      [
        '${company_phone}',
        'номер телефона компании',
        '71 200 74 00, 71 200 12 00',
      ],
      ['${client_birthday}', 'день рождения клиента', '01.01.1983, 02.02.2022'],
      ['${passport_series}', 'серия паспорта', 'AA123456, AC654321'],
      [
        '${issued_by_whom}',
        'кем выдан паспорт',
        'Тошкент шахар Юнособот тумани ИИБ, Самарканд вмлояти, Каттакургон тумани ИИБ',
      ],
      ['${issued_date}', 'дата выдачи паспорта', '26.08.2018, 27.01.2013'],
      [
        '${client_phone}',
        'номер телефона клиента',
        '+998901234567, +998993214321',
      ],
      [
        '${client_secondary_phone}',
        'дополнительный номер телефона клиента',
        '+998913221232, +998933211234',
      ],
      ['${payment_table}', 'таблица для оплаты рассрочки', 'Таблица'],
      [
        '${reservation_price}',
        'стоимость бронирования',
        '2 000 000, 3 000 000',
      ],
      [
        '${reservation_price_text}',
        'стоимость бронирования в текстовом формате',
        'икки миллион, уч миллион',
      ],
      ['${reservation_period}', 'срок бронирования', '10, 20'],
      [
        '${reservation_time_from}',
        'дата бронирования с (дата начала)',
        '2021 йил “10” декабрь, 2022 йил “22” январь',
      ],
      [
        '${reservation_time_to}',
        'дата бронирования по (дата окончания)',
        '2021 йил “01” февраль, 2022 йил “02” март',
      ],
      [
        '${prepay_percent}',
        'процент первоначальной оплаты',
        '30, 25(знак % есть в договоре)',
      ],
    ]

    return {
      rawItems,
    }
  },
  computed: {
    newName() {
      return this.$t('new_name')
    },
    meaning() {
      return this.$t('meaning')
    },
    example() {
      return this.$t('example_by_2')
    },
    fields() {
      return [this.newName, this.meaning, this.example]
    },
    items() {
      return this.rawItems.map(item => {
        const gap = {}
        gap[this.newName] = item[0]
        gap[this.meaning] = item[1]
        gap[this.example] = item[2]
        return gap
      })
    },
  },
  methods: {
    hideModal() {
      this.$bvModal.hide('instructions-content')
    },
  },
}
</script>

<template>
  <b-modal
    id="instructions-content"
    ref="instructions-content"
    :hide-header="true"
    size="lg"
    hide-footer
  >
    <div>
      <h4 class="mb-4">
        {{ $t("instruction") }}
      </h4>
      <b-table
        :fields="fields"
        :items="items"
        :bordered="true"
        :striped="true"
      />

      <b-button
        variant="light"
        style="float: right; margin-right: 0"
        @click="hideModal"
      >
        {{ $t("close") }}
      </b-button>
    </div>
  </b-modal>
</template>
