var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-header',{scopedSlots:_vm._u([{key:"header-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("objects.title"))+" ")]},proxy:true},{key:"header-actions",fn:function(){return [_c('hide-price-button'),(_vm.viewHoldLogsPermission)?_c('router-link',{attrs:{"to":"/hold-logs"}},[_c('x-circular-background',{staticClass:"bg-violet-500",staticStyle:{"user-select":"none","cursor":"pointer"},attrs:{"padding":0.5}},[_c('x-icon',{staticStyle:{"user-select":"none"},attrs:{"name":"person_search","size":24,"color":"white"}})],1)],1):_vm._e(),(_vm.permission.hasAdminRole())?_c('base-button',{attrs:{"text":_vm.archivedButtonText},on:{"click":_vm.setArchive},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('x-icon',{staticClass:"violet-600",attrs:{"name":_vm.archivedButtonIcon,"size":20,"color":"var(--violet-600)"}})]},proxy:true}],null,false,236588613)}):_vm._e()]},proxy:true}])}),_vm._m(0),_c('div',{staticClass:"object-cards"},[(_vm.viewPermission)?_vm._l((_vm.getObjects),function(object,index){return _c('div',{key:index,staticClass:"card"},[(
            _vm.deletePermission ||
            _vm.editPermission ||
            _vm.logoUploadPermission ||
            _vm.promosViewPermission ||
            _vm.plansViewPermission
          )?_c('div',{staticClass:"object__more-info"},[_c('x-dropdown',[(_vm.editPermission)?_c('b-dropdown-item',[_c('router-link',{class:'dropdown-item',attrs:{"to":{ name: 'objectsEdit', params: { id: object.id } }}},[_c('i',{staticClass:"fas fa-pen"}),_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")])],1):_vm._e(),(_vm.editPermission)?_c('b-dropdown-item',[_c('b-link',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.archiveObject(object)}}},[_c('i',{staticClass:"fas fa-archive"}),_vm._v(" "+_vm._s(_vm.archived ? _vm.$t("unarchiveV") : _vm.$t("archiveV"))+" ")])],1):_vm._e(),(_vm.promosViewPermission)?_c('b-dropdown-item',[_c('router-link',{class:'dropdown-item',attrs:{"to":{ name: 'objects-promo', params: { id: object.id } }}},[_c('i',{staticClass:"fas fa-gift"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("promo.promos"))+" ")])])],1):_vm._e(),(_vm.promosViewPermission)?_c('b-dropdown-item',[_c('router-link',{class:'dropdown-item',attrs:{"to":{
                  name: 'objects-installment',
                  params: { id: object.id },
                }}},[_c('i',{staticClass:"fas fa-calendar"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("Installment"))+" ")])])],1):_vm._e(),(_vm.plansViewPermission)?_c('b-dropdown-item',[_c('router-link',{class:'dropdown-item',attrs:{"to":{ name: 'type-plan-view', params: { id: object.id } }}},[_c('i',{staticClass:"fal fa-credit-card"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("type_plan.title"))+" ")])])],1):_vm._e(),(_vm.plansViewPermission)?_c('b-dropdown-item',[_c('router-link',{class:'dropdown-item',attrs:{"to":{ name: 'type-parking-view', params: { id: object.id } }}},[_c('i',{staticClass:"fal fa-parking"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("parkings"))+" ")])])],1):_vm._e(),(_vm.manageFacilitiesPermission)?_c('b-dropdown-item',[_c('router-link',{class:'dropdown-item',attrs:{"to":{ name: 'facilities', params: { object: object.id } }}},[_c('i',{staticClass:"fal fa-map-marker-alt"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("Facilities"))+" ")])])],1):_vm._e(),(_vm.logoUploadPermission)?_c('b-dropdown-item',[_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-upload-logo",modifiers:{"modal-upload-logo":true}}],staticClass:"dropdown-item",on:{"click":function($event){_vm.object_id = object.id}}},[_c('i',{staticClass:"fas fa-image"}),_vm._v(" "+_vm._s(_vm.$t("upload_logo"))+" ")])],1):_vm._e(),(_vm.deletePermission)?_c('b-dropdown-item',[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){return _vm.deleteObject(object.id)}}},[_c('i',{staticClass:"fas fa-trash"}),_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")])]):_vm._e()],1)],1):_vm._e(),_c('router-link',{staticClass:"card-body",attrs:{"event":_vm.apartmentsViewPermission ? 'click' : '',"to":{
            name: 'apartments',
            params: { object: object.id },
            query: { limit: 20, page: 1 },
          }}},[_c('div',{staticClass:"card-top"},[_c('div',{staticClass:"card-top__content"},[_c('h5',{staticClass:"card-title"},[_vm._v(" "+_vm._s(object.name)+" ")]),_c('div',{staticClass:"card-subtitle"},[_vm._v(" "+_vm._s(object.address)+" ")])])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"card-block"},[_c('p',{staticClass:"card-block__title"},[_vm._v(" "+_vm._s(object.apartments_count)+" "+_vm._s(_vm.$t("objects.view_apartments"))+" ")]),(_vm.showPrice)?_c('p',{staticClass:"card-block__subtitle price"},[_vm._v(" "+_vm._s(_vm.$t("price_from", { msg: _vm.priceFormat(object.apartment_price), }))+" ")]):_vm._e()]),_c('div',{staticClass:"card-block"},[_c('p',{staticClass:"card-block__title"},[_vm._v(" "+_vm._s(object.floors_count)+" "+_vm._s(_vm.$t("objects.view_level"))+" ")]),(!object.is_hide_m2_price && _vm.showPrice)?_c('p',{staticClass:"card-block__subtitle",domProps:{"innerHTML":_vm._s(
                  _vm.$t('price_from_m2', {
                    msg: `${_vm.priceFormat(object.apartment_price_m2)}`,
                  })
                )}}):_vm._e()]),(object.is_parking)?_c('div',{staticClass:"card-block"},[_c('p',{staticClass:"card-block__title"},[_vm._v(" "+_vm._s(object.parking_count)+" "+_vm._s(_vm.$t("objects.view_parkings"))+" ")]),(_vm.showPrice)?_c('p',{staticClass:"card-block__subtitle",domProps:{"innerHTML":_vm._s(
                  _vm.$t('price_from', {
                    msg: `${_vm.priceFormat(object.parking_initial_price)}`,
                  })
                )}}):_vm._e()]):_vm._e()])]),_c('router-link',{staticClass:"card-img",attrs:{"event":_vm.apartmentsViewPermission ? 'click' : '',"to":{ name: 'apartments', params: { object: object.id } }}},[(object.image)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(object.image),expression:"object.image"}],attrs:{"alt":""}}):_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('@/assets/img/not-found.png')),expression:"require('@/assets/img/not-found.png')"}],attrs:{"alt":""}})])],1)}):_vm._e(),(_vm.createPermission && !_vm.archived)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body card-empty",on:{"click":_vm.createBlock}},[_c('img',{attrs:{"src":require('@/assets/icons/icon-plus.svg'),"alt":""}}),_c('p',[_vm._v(_vm._s(_vm.$t("object_create")))])])]):_vm._e()],2),(_vm.logoUploadPermission)?_c('upload-logo',{attrs:{"object-id":_vm.object_id},on:{"UploadLogo":_vm.uploadLogo}}):_vm._e(),_c('b-overlay',{attrs:{"show":_vm.getLoading,"no-wrap":"","opacity":"0.5"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center w-100"},[_c('div',{staticClass:"lds-ellipsis"},[_c('div'),_c('div'),_c('div'),_c('div')])])]},proxy:true}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search__content"},[_c('div'),_c('div',{staticClass:"d-flex x-gap-1"})])
}]

export { render, staticRenderFns }