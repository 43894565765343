<script>
import BaseFilterIcon from "@/components/icons/BaseFilterIcon";
import BaseButton from "@/components/Reusable/BaseButton";
import BaseArrowLeftIcon from "@/components/icons/BaseArrowLeftIcon";
import BaseNumericInput from "@/components/Reusable/BaseNumericInput";
import BaseFormTagInput from "@/components/Reusable/BaseFormTagInput";
import BaseCheckbox from "@/components/Reusable/BaseCheckbox2";
// import BaseMultiselect from "@/components/Reusable/BaseMultiselect";
import { XFormSelect } from "@/components/ui-components/form-select";
import "vue2-datepicker/index.css";
import BaseDatePicker from "@/components/Reusable/BaseDatePicker";
import { debounce, isPrimitiveValue, sortObjectValues } from "@/util/reusable";
import api from "@/services/api";
import {
  isArray,
  isNUNEZ,
  isString,
  isUndefinedOrNullOrEmpty,
} from "@/util/inspect";
import { hasOwnProperty } from "@/util/object";
import BaseLoading from "@/components/Reusable/BaseLoading.vue";
import ContractsPermission from "@/permission/contract";
import { v3ServiceApi } from "@/services/v3/v3.service";

export default {
  name: "SearchBarContent",
  components: {
    XFormSelect,
    BaseCheckbox,
    BaseFilterIcon,
    BaseArrowLeftIcon,
    BaseNumericInput,
    BaseLoading,
    BaseFormTagInput,
    // BaseMultiselect,
    BaseDatePicker,
    BaseButton,
  },
  emits: ["trigger-input", "search-by-filter", "replace-router"],
  data() {
    return {
      filter: {
        object_id: [],
        apartment: [],
        user_id: [],
        created_at: [],
      },

      objectOptions: [],
      managerOptions: [],
    };
  },
  computed: {
    query() {
      return { ...this.$route.query };
    },
  },
  watch: {},
  mounted() {
    this.filterModalOpened();
    this.fetchObjectsOption();
  },
  methods: {
    sortInFirstRelationship(obj) {
      const loopPackage = {};
      for (const [key, value] of Object.entries(obj)) {
        if (!value) continue;

        if (Array.isArray(value) && value.length > 0) {
          const valuable = value.filter((arrValue) => arrValue !== null);
          if (valuable.length) {
            loopPackage[key] = valuable;
          }
          continue;
        }

        const types = ["number", "string", "boolean", "symbol"];
        const typeOfValue = typeof value;
        const haveInTypes = types.some((type) => type === typeOfValue);
        if (haveInTypes) {
          /* REJECT VALUE WHEN ZERO */
          const rejectZeroString = typeOfValue === "string" && value !== "0";
          const rejectZeroNumber = typeOfValue === "number" && value !== 0;
          if (rejectZeroString || rejectZeroNumber) {
            loopPackage[key] = value;
          }
        }
      }
      return loopPackage;
    },
    async fetchObjectsOption() {
      await api.contractV2
        .fetchObjectsOption()
        .then((response) => {
          const { objects, managers } = response.data;

          this.objectOptions = objects;

          this.managerOptions = managers.map((m) => {
            let text = "";
            if (isNUNEZ(m.last_name)) {
              text += m.last_name;
            }

            if (isNUNEZ(m.first_name)) {
              text += ` ${m.first_name}`;
            }

            if (isNUNEZ(m.second_name)) {
              text += ` ${m.second_name}`;
            }

            return {
              id: m.id,
              text: text.trim(),
            };
          });
        })
        .catch((error) => {
          this.toastedWithErrorCode(error);
        });
    },
    clearFilter() {
      this.resetFilter();
      const loopQuery = { page: 1, limit: this.query.limit };

      this.$emit("replace-router", loopQuery);
      this.$refs["base-form-tag-input"].clear();

      this.hideFilterModal();
    },
    searchByFilterField() {
      const object_id = this.filter.object_id
        ? this.filter.object_id.map((obj) => obj.id)
        : null;
      const sortingQuery = {
        ...this.query,
        ...this.filter,
        object_id,
      };
      sortingQuery.page = 1;
      this.$emit(
        "search-by-filter",
        this.sortInFirstRelationship(sortingQuery)
      );
      this.$refs["filter-modal"].hide();
    },
    hideFilterModal() {
      this.$refs["filter-modal"].hide();
    },
    resetFilter() {
      this.filter = {
        object_id: [],
        apartment: [],
        user_id: [],
        created_at: [],
      };
    },
    async showFilterModal() {
      this.filter.created_at = this.query.created_at || [];
      this.$refs["filter-modal"].show();
    },

    triggerInputEvent() {
      const query = { ...this.query };
      const searchValue = this.debounceInput;
      if (searchValue?.length) {
        query.search = searchValue;
        this.pushRouter(query);
      } else {
        query.search = null;
        this.pushRouter(query);
      }
    },
    pushRouter(query) {
      const sortQuery = sortObjectValues(query);
      this.$router.push({ query: {} });
      this.$router.push({ query: sortQuery });
    },
    setApartments(apartments) {
      this.filter.apartment = apartments;
    },
    setContractNumbers(contractNumbers) {
      this.filter.contract_number = contractNumbers;
    },
    filterModalOpened() {
      const haveInRouteQuery = (property) => {
        const query = { ...this.query };
        const hasInQuery = hasOwnProperty(query, property);
        if (hasInQuery) return query[property];
        return false;
      };
      for (const property of Object.keys(this.filter)) {
        const query = haveInRouteQuery(property);
        if (
          ["apartment", "contract_number"].includes(property) &&
          typeof query === "string"
        ) {
          this.filter[property] = [query];
          continue;
        }

        if (property === "object_id" && query) {
          if (isPrimitiveValue(query)) {
            const cObj = this.objectOptions.find(
              (opObj) => opObj.id === parseInt(query)
            );
            if (cObj) {
              this.filter[property] = [cObj];
            }
          } else {
            this.filter[property] = query.map((value) => {
              const obj = this.objectOptions.find(
                (opObj) => opObj.id === parseInt(value)
              );
              if (obj) {
                return obj;
              }
              return value;
            });
          }
          continue;
        }

        const arrayProps = ["user_id"];
        if (arrayProps.includes(property)) {
          if (isArray(query)) {
            this.filter[property] = query.map((p) => parseInt(p));
          } else if (isString(query)) {
            this.filter[property] = [parseInt(query)];
          } else if (query) {
            this.filter[property] = query;
          }
        } else if (query) {
          this.filter[property] = query;
        }
      }
    },
  },
};
</script>

<template>
  <div class="filter-block">
    <base-button :text="`${$t('contracts.filter')}`" @click="showFilterModal">
      <template #left-icon>
        <base-filter-icon fill="#7C3AED" />
      </template>
    </base-button>

    <!--  FILTER MODAL   -->
    <b-modal
      ref="filter-modal"
      title="Using Component Methods"
      modal-class="filter__modal"
      hide-header
      hide-footer
      @show="filterModalOpened"
      @hidden="hideFilterModal"
    >
      <div class="modal__content">
        <!--   Go Back     -->
        <span class="d-flex align-items-center">
          <span class="go__back" @click="hideFilterModal">
            <base-arrow-left-icon :width="32" :height="32" />
          </span>
          <!--    Title      -->
          <span class="title"> {{ $t("contracts.filter_title") }} </span>
        </span>

        <div class="modal__content-main">
          <div class="filter__inputs">
            <x-form-select
              v-model="filter.object_id"
              value-field="id"
              class="mt-3"
              text-field="name"
              getter="full"
              :multiple="true"
              :options="objectOptions"
              :placeholder="$t('contracts.object_name')"
            />
            <!--    Filter Apartment Number      -->
            <div class="filter__inputs-input">
              <base-form-tag-input
                ref="base-form-tag-input"
                :default-tags="filter.apartment"
                :placeholder="`${$t('contracts.apartment_number')}`"
                @set-tags="setApartments"
              >
                <template #delete-content>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="10" cy="10" r="10" fill="#9CA3AF" />
                    <path
                      d="M13.125 6.875L6.875 13.125"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.875 6.875L13.125 13.125"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </template>
              </base-form-tag-input>
            </div>

            <x-form-select
              v-model="filter.user_id"
              :options="managerOptions"
              value-field="id"
              text-field="text"
              class="mt-3"
              :multiple="true"
              :placeholder="$t('users.title')"
            />
            <base-date-picker
              :value="filter.created_at"
              class="w-100 mt-3"
              :default-value="filter.created_at"
              :placeholder="`${$t('created_at')}`"
              @input="filter.created_at = $event"
            />
          </div>

          <!--  Modal Footer    -->
          <div class="modal__footer">
            <base-button
              :fixed="true"
              design="release-info"
              :text="`${$t('contracts.reset_filter')}`"
              @click="clearFilter"
            />
            <base-button
              design="violet-gradient"
              :fixed="true"
              :text="`${$t('contracts.apply_filter')}`"
              @click="searchByFilterField"
            />
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<style lang="scss" scoped>
.filter-block {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

::v-deep .filter__modal {
  overflow-y: hidden;
  background: rgba(156, 163, 175, 0.32);
  backdrop-filter: blur(16px);

  .modal-dialog {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: hidden;
    margin: 0;
    border-radius: 3.5rem 0 0 3.5rem;
    min-width: 45rem;

    .modal-content {
      height: 100%;
      overflow-y: hidden;
    }
  }

  .modal-body {
    height: 100%;
    overflow-x: auto;
  }

  .modal__content {
    margin: 3rem;
    font-family: CraftworkSans, serif;
    font-weight: 900;
    height: 92%;
    position: relative;

    &-main {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      row-gap: 100px;
    }

    .title {
      font-size: 1.75rem;
      line-height: 1.75rem;
      display: block;
      margin-left: 1.5rem;
      color: var(--gray-600);
      padding: 0.5rem 0;
    }

    .filter__inputs {
      margin-top: 2rem;
      margin-bottom: 3rem;
      padding-bottom: 150px;
      .input__date.mx-datepicker.mx-datepicker-range {
        width: 60% !important;
      }

      &-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 20rem;
        min-height: 4rem;
        background-color: var(--gray-100);
        border-radius: 2rem;
        padding: 1rem 1.25rem;
        margin-top: 1.5rem;
        width: 100%;
        border: none;
        color: var(--gray-600);
        position: relative;

        ::placeholder {
          color: var(--gray-600);
          opacity: 1;
        }

        .placeholder {
          color: var(--gray-600);
          //padding-left: 1rem;
        }

        .input__date {
          margin-left: 0.5rem;
          background-color: transparent;
          border: none;
        }

        .inline {
          background-color: transparent;
          border: none;
          color: var(--gray-600);
          padding: 0;

          .disabled__option {
            color: var(--gray-100) !important;
          }
        }
      }
    }
  }
}

.go__back {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background-color: var(--gray-100);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: var(--gray-200);
  }
}

.modal__footer {
  display: flex;
  align-items: flex-end;
  width: 590px;
  height: 150px;
  column-gap: 0.5rem;
  padding-bottom: 3rem;
  position: fixed;
  bottom: 0;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 0.8) 80%,
    rgba(255, 255, 255, 0)
  );
}

::v-deep .filter__inputs-tag {
  li.b-form-tag {
    background-color: #ffffff;
    display: flex;
    border-radius: 1rem;
    align-items: center;

    .b-form-tag-content {
      color: var(--gray-600);
      font-family: Inter, sans-serif;
      font-size: 1rem;
      display: flex;
      align-items: flex-start;
    }

    .b-form-tag-remove {
      background-color: var(--gray-400);
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      font-size: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
  }
}

.filter__price {
  border: none;
  outline: none;
  background-color: transparent;
  height: 100%;
  max-width: 156px;
  padding-left: 1rem;
}

.filter__apartment__price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 20rem;
  min-height: 4rem;
  background-color: var(--gray-100);
  border-radius: 2rem;
  padding: 0 1.25rem;
  margin-top: 1.5rem;
  width: 100%;
  border: none;
  color: var(--gray-600);
  position: relative;

  .middle__position {
    align-self: stretch;
    min-height: 4rem;
    height: 100%;
    display: flex;
    align-items: center;
    border-left: 2px solid var(--gray-200);
    border-right: 2px solid var(--gray-200);
  }

  ::placeholder {
    color: var(--gray-600);
    opacity: 1;
  }

  .placeholder {
    color: var(--gray-600);
  }

  .input__date {
    margin-left: 0.5rem;
    background-color: transparent;
    border: none;
    width: auto;
  }

  .inline {
    background-color: transparent;
    border: none;
    color: var(--gray-600);
    padding: 0;

    .disabled__option {
      color: var(--gray-100) !important;
    }
  }

  .price__currency {
    min-width: 6rem;
    height: 100%;
    //padding-left: 1rem;
  }
}

::placeholder {
  color: var(--gray-600);
  opacity: 1;
}

input[type="date"] {
  padding-right: 2rem;
}

input[type="date"]::-webkit-datetime-edit-text,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field,
input[type="date"]::-webkit-datetime-edit-year-field {
  color: var(--gray-600);
}

::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 60%;
  right: 2rem;
  transform: translateY(-60%);
}
</style>
