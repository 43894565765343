<script>
import { makeProp as p } from "@/util/props";
import clickOutside from "@/directives/click-outside";
import BaseDownIcon from "@/components/icons/BaseDownIcon";
import { KChipInputGroup } from "@/components/ui-components/chip-input-group";
import {
  PROP_TYPE_ARRAY,
  PROP_TYPE_STRING,
  PROP_TYPE_BOOLEAN,
  PROP_TYPE_OBJECT_STRING,
  PROP_TYPE_ARRAY_OBJECT_NUMBER_STRING,
} from "@/constants/props";
import {
  isArray,
  isNull,
  isObject,
  isPrimitive,
  isUndefinedOrNull,
} from "@/util/inspect";
import BaseLeftIcon from "@/components/icons/BaseLeftIcon";
import BaseCheckIcon from "@/components/icons/BaseCheckIcon";

export default {
  name: "XFormSelect",
  components: {
    BaseDownIcon,
    KChipInputGroup,
    BaseLeftIcon,
    BaseCheckIcon,
  },
  directives: {
    clickOutside,
  },
  model: {
    prop: "value",
    event: "change",
  },
  emits: ["change"],
  props: {
    id: p(PROP_TYPE_STRING),
    name: p(PROP_TYPE_STRING),
    bilingual: p(PROP_TYPE_BOOLEAN, false),
    disabled: p(PROP_TYPE_BOOLEAN, false),
    value: p(PROP_TYPE_ARRAY_OBJECT_NUMBER_STRING),
    options: p(PROP_TYPE_ARRAY, []),
    valueField: p(PROP_TYPE_STRING, "value"),
    textField: p(PROP_TYPE_STRING, "text"),
    optionStyle: p(PROP_TYPE_OBJECT_STRING, {}),
    optionClass: p(PROP_TYPE_OBJECT_STRING, {}),
    placeholder: p(PROP_TYPE_STRING, undefined),
    label: p(PROP_TYPE_BOOLEAN, true),
    error: p(PROP_TYPE_BOOLEAN, false),
    getter: p(PROP_TYPE_STRING, "value", (vGetter) =>
      ["full", "text", "value"].includes(vGetter)
    ),
    multilingual: p(PROP_TYPE_BOOLEAN, false),
    bottomToTop: p(PROP_TYPE_BOOLEAN, false),
    topToBottom: p(PROP_TYPE_BOOLEAN, false),

    regions: {
      type: Array,
      required: true,
    },
  },
  data() {
    const selected = this.multiple ? [] : null;
    return {
      currentRegion: null,
      open: false,
      showBottomToTop: false,
      formOptionsWrapperHeight: 0,
    };
  },
  computed: {
    displayLabel() {
      if (!this.value || !this.selectedRegion) return "sssssss";
      return (
        this.selectedRegion.name[this.$i18n.locale] +
        ", " +
        this.selectedDistrict.name[this.$i18n.locale]
      );
    },
    selectedRegion() {
      return this.regions.find((el) =>
        el.districts.some((d) => d.id === this.value)
      );
    },
    selectedDistrict() {
      if (!this.selectedRegion) return "";
      return this.selectedRegion.districts.find((el) => el.id === this.value);
    },
    optionWrapperStyle() {
      if (!this.open) {
        return {
          display: "none",
        };
      }
      return {};
    },
    showPlaceholder() {
      if (this.hasPlaceholderSlot) {
        return false;
      }

      if (this.label) {
        return true;
      }

      if (this.multiple) {
        return !this.selected.length;
      }

      return !this.selected;
    },
    showLabel() {
      const { value } = this;
      if (!this.label) return false;
      return !isNull(value);
    },
    localePlaceholder() {
      const { placeholder } = this;
      if (placeholder) {
        return placeholder;
      }
      return this.$t("please_select");
    },
    hasPlaceholderSlot() {
      return this.$slots.hasOwnProperty("placeholder");
    },
    hasOutputSlot() {
      return this.$slots.hasOwnProperty("output");
    },
    selectList() {
      if (this.multiple) {
        if (this.selected?.length) {
          return {
            show: true,
            value: this.selected,
          };
        }
        return {
          show: false,
          value: [],
        };
      }
      const { selected, textField } = this;
      if (selected) {
        return {
          show: true,
          value: selected[textField],
        };
      }
      return {
        show: false,
        value: [],
      };
    },
  },
  watch: {
    value(latestValue) {
      if (
        !this.multiple &&
        (!this.selected || this.selected.value !== latestValue)
      ) {
        this.lunch();
        this.findOutputPosition();
      }
    },
  },
  mounted() {
    this.lunch();
    this.findOutputPosition();
  },
  beforeUpdate() {
    this.findOutputPosition();
  },
  methods: {
    setDistrict(id) {
      this.$emit("change", id);
      this.closeOptionList();
    },
    setRegion(id) {
      this.currentRegion = id;
      setTimeout(() => {
        this.$refs["k-form-options-wrapper"]
          .querySelector(".x-form-select-options")
          .scroll(0, 0);
      }, 0);
    },
    findOutputPosition() {
      if (this.topToBottom) return (this.showBottomToTop = false);
      const windowHeight = window.innerHeight;
      const formSelectRect =
        this.$refs["x-form-select"].getBoundingClientRect();
      const { height: optionsTotalHeight } =
        this.$refs["k-form-options-wrapper"].getBoundingClientRect();
      const distanceCellBetweenBottom = windowHeight - formSelectRect.bottom;
      this.showBottomToTop =
        this.bottomToTop ||
        distanceCellBetweenBottom < formSelectRect.height * 3 + 50;
      // distanceCellBetweenBottom < formSelectRect.height + optionsTotalHeight;
    },
    lunch() {
      const { textField, valueField } = this;
      const _dValue = this.$attrs.value ?? this.value;
      const typeArray = isArray(_dValue);
      const typeObject = isObject(_dValue);
      if (isUndefinedOrNull(_dValue)) {
        this.selected = null;
        this.inactiveAllOption();
        return;
      }
      if (this.multiple) {
        if (typeArray) {
          const isContainObjects = _dValue.every((v) => isObject(v));
          const isContainPrimitives = _dValue.every((v) => isPrimitive(v));
          if (isContainObjects) {
            this.selected = [..._dValue];
          } else if (isContainPrimitives) {
            this.selected = _dValue.map((_vc) => {
              const _fChild = this.findOption(_vc);
              if (isUndefinedOrNull(_fChild)) {
                const c = {};
                c[valueField] = _vc;
                c[textField] = _vc;
                return c;
              }
              return _fChild.option;
            });
          }
        } else if (typeObject) {
          this.selected.push(_dValue);
        }
      } else if (typeArray) {
        if (_dValue.length) {
          this.selected = _dValue[0];
        }
      } else if (typeObject) {
        const majorValue = _dValue[valueField];
        this.selected = this.findOption(majorValue).option ?? _dValue;
      } else if (isPrimitive(_dValue)) {
        this.selected = {};
        const _fChild = this.findOption(_dValue);
        if (isUndefinedOrNull(_fChild)) {
          this.selected[this.textField] = _dValue;
          this.selected[this.valueField] = _dValue;
        } else {
          this.selected = _fChild.option;
          this.inactiveAllOption();
          _fChild.makeActive();
        }
      }
    },
    handleChange() {
      const { multiple, selected, valueField: vField } = this;

      if (isUndefinedOrNull(selected)) {
        this.$emit("change", null);
        return;
      }

      switch (this.getter) {
        case "value": {
          this.$emit("change", selected[vField]);
          break;
        }
        case "text": {
          const _cText = selected.map((vSelect) => vSelect[this.textField]);
          this.$emit("change", _cText);
          break;
        }
        default: {
          this.$emit("change", selected);
        }
      }
    },
    selectHandler({ value, text, disabled }) {
      if (disabled) {
        if (this.multiple) {
          this.selected = this.selected.filter(
            (s) => s[this.valueField] !== value
          );
        } else {
          this.selected = null;
        }
      } else if (this.multiple) {
        const option = this.generateOps(value, text);
        if (isUndefinedOrNull(this.selected)) {
          this.selected = [];
        }
        this.selected.push(option);
      } else {
        this.selected = this.generateOps(value, text);
      }
    },
    generateOps(value, text) {
      const ops = {};
      ops[this.valueField] = value;
      ops[this.textField] = text;
      return ops;
    },
    toggleOptionList() {
      if (this.disabled) return;
      this.open = !this.open;
    },
    openOptionList() {
      this.open = true;
    },
    closeOptionList() {
      this.open = false;
    },
    clear() {
      this.selected = this.multiple ? [] : null;
      this.inactiveAllOption(null);
    },
    optionSelected(chOption) {
      const { textField, valueField } = this;
      const hTextField = chOption.hasOwnProperty(textField);
      const hValueField = chOption.hasOwnProperty(valueField);

      if (!(hValueField || hTextField)) return;

      const _value = chOption[valueField];
      const _text = chOption[textField];

      let value;
      let text;
      if (hValueField) {
        if (hTextField) {
          value = _value;
          text = _text;
        } else {
          value = chOption;
          text = chOption;
        }
      } else if (hTextField) {
        value = _text;
        text = _text;
      }

      this.selectHandler({ value, text, disabled: chOption.disabled });
    },
    findOption(optionValue) {
      const { valueField: vField } = this;
      return this.$children.find(
        (_ch) =>
          _ch.$el.tagName.toLocaleLowerCase() === "li" &&
          _ch.option[vField] === optionValue
      );
    },
    getOptionIndex(_value) {
      return this.$children.findIndex(
        (_ch) =>
          _ch.$el.tagName.toLocaleLowerCase() === "li" &&
          _ch.option.value === _value
      );
    },
    inactiveOption(_oValue) {
      this.$children.forEach((ch, idx) => {
        const isOption = ch.$el.tagName.toLocaleLowerCase() === "li";
        if (isOption && ch.option[this.valueField] === _oValue) {
          this.$children[idx].checked = false;
        }
      });
    },
    inactiveAllOption(_cValue = undefined) {
      this.$children.forEach((ch, idx) => {
        const isOption = ch.$el.tagName.toLocaleLowerCase() === "li";
        if (isOption && ch.option[this.valueField] !== _cValue) {
          this.$children[idx].checked = false;
        }
      });
    },
    getValueByCurrentLang(value) {
      if (value.hasOwnProperty(this.$i18n.locale)) {
        return value[this.$i18n.locale];
      }
      return value;
    },
  },
};
</script>

<template>
  <div
    ref="x-form-select"
    v-click-outside="closeOptionList"
    class="x-form-select"
  >
    <div
      class="x-form-select-header"
      :class="{ 'select-validation-failed': error }"
      @click="toggleOptionList"
    >
      <div
        class="x-form-select-header-content"
        :class="{
          'k-content-flex': selectList,
        }"
      >
        <div :class="{ 'k-form-option-label': showLabel }" class="placeholder">
          <span v-if="showPlaceholder" class="placeholder">
            {{ localePlaceholder }}
          </span>
        </div>
        <div v-if="value">
          <div>
            <span>{{ displayLabel }}</span>
          </div>
        </div>
      </div>
      <span
        class="x-form-select-icon"
        :class="{ 'x-form-select-icon-open': open }"
      >
        <base-down-icon />
      </span>
    </div>
    <div
      ref="k-form-options-wrapper"
      class="x-form-select-main"
      :style="optionWrapperStyle"
      :class="{ 'x-form-select-position-top': showBottomToTop }"
    >
      <ul class="x-form-select-options" v-if="!currentRegion">
        <li
          v-for="region in regions"
          :key="`x-form-select-option-${region.id}`"
          class="k-form-option"
          :class="{ 'k-form-option-active': selectedRegion?.id === region.id }"
          @click.stop="setRegion(region.id)"
        >
          <span>
            <span>{{ region.name[$i18n.locale] }}</span>
          </span>

          <span
            v-if="selectedRegion && selectedRegion.id === region.id"
            class="option-check-icon"
          >
            <base-check-icon fill="#7C3AED" :width="20" :height="20" />
          </span>
        </li>
      </ul>
      <ul class="x-form-select-options" v-else>
        <li
          class="k-form-option"
          @click.stop="currentRegion = null"
          style="justify-content: start; gap: 20px"
        >
          <base-left-icon />
          <span>
            <span>{{ $t("back") }}</span>
          </span>
        </li>
        <li
          v-for="district in regions.find((el) => el.id === currentRegion)
            ?.districts || []"
          :key="`x-form-select-option-${district.id}`"
          class="k-form-option"
          :class="{ 'k-form-option-active': value === district.id }"
          @click="setDistrict(district.id)"
        >
          <span>
            <span>{{ district.name[$i18n.locale] }}</span>
          </span>

          <span v-if="district.id === value" class="option-check-icon">
            <base-check-icon fill="#7C3AED" :width="20" :height="20" />
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" src="./form-select.scss" scoped/>
<style lang="scss" src="./form-select-option.scss" scoped />

<style lang="scss" scoped>
.placeholder {
  color: var(--gray-400);
}
</style>
