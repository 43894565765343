export const NOTIFY = {
  type: {
    success: 'success',
    error: 'error',
  },
}

export const TYPE = {
  '1 взнос': 'first_payment',
  ежемесячный: 'monthly',
  бронирован: 'booked',
  'за перегородку': 'for partition wall',
  реклама: 'advertising',
  'қайтариш кв.': 'return_flat',
  Нежилой: 'non_residential',
  хайрия: 'charity',
}

export const PAYMENT_TYPE = {
  Перечисление: 'transfer',
  Наличный: 'cash',
  Перерасчет: 'Recalculation',
  'К Хуршидака': 'Client_khurshidaka',
}

export const PAGINATION_COUNT = 20
export const DEBTORS_EXCEL_FILES = 'debtors_excel_files'
