import state from '@/views/parking/checkout/store/state'
import getters from '@/views/parking/checkout/store/getters'
import mutations from '@/views/parking/checkout/store/mutations'
import actions from '@/views/parking/checkout/store/actions'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
