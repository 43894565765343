<script>
export default {
  name: 'BaseUserAccountIcon',
  props: {
    extraClass: {
      type: String,
      required: false,
    },
    fill: {
      type: String,
      default: '#9CA3AF',
    },
    width: {
      type: Number,
      default: 20,
    },
    height: {
      type: Number,
      default: 20,
    },
  },
}
</script>

<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="user__account__icons"
    :class="extraClass"
  >
    <path
      :fill="fill"
      d="M15.71 12.7096C16.6904 11.9383 17.406 10.8806 17.7572 9.68358C18.1085 8.4866 18.0779 7.2099 17.6698 6.0311C17.2617 4.85231 16.4963 3.83003 15.4801 3.10649C14.4639 2.38296 13.2474 1.99414 12 1.99414C10.7525 1.99414 9.53611 2.38296 8.51993 3.10649C7.50374 3.83003 6.73834 4.85231 6.33021 6.0311C5.92208 7.2099 5.89151 8.4866 6.24276 9.68358C6.59401 10.8806 7.3096 11.9383 8.29 12.7096C6.61007 13.3827 5.14428 14.499 4.04889 15.9396C2.95349 17.3801 2.26956 19.0909 2.07 20.8896C2.05555 21.021 2.06711 21.1538 2.10402 21.2807C2.14093 21.4075 2.20246 21.5259 2.28511 21.629C2.45202 21.8371 2.69478 21.9705 2.96 21.9996C3.22521 22.0288 3.49116 21.9514 3.69932 21.7845C3.90749 21.6176 4.04082 21.3749 4.07 21.1096C4.28958 19.1548 5.22168 17.3494 6.68822 16.0384C8.15475 14.7274 10.0529 14.0027 12.02 14.0027C13.9871 14.0027 15.8852 14.7274 17.3518 16.0384C18.8183 17.3494 19.7504 19.1548 19.97 21.1096C19.9972 21.3554 20.1144 21.5823 20.2991 21.7467C20.4838 21.911 20.7228 22.0011 20.97 21.9996H21.08C21.3421 21.9695 21.5817 21.8369 21.7466 21.6309C21.9114 21.4248 21.9881 21.162 21.96 20.8996C21.7595 19.0958 21.0719 17.3806 19.9708 15.9378C18.8698 14.4951 17.3969 13.3791 15.71 12.7096ZM12 11.9996C11.2089 11.9996 10.4355 11.765 9.77772 11.3255C9.11992 10.886 8.60723 10.2613 8.30448 9.53037C8.00173 8.79947 7.92251 7.9952 8.07686 7.21928C8.2312 6.44335 8.61216 5.73062 9.17157 5.17121C9.73098 4.6118 10.4437 4.23084 11.2196 4.0765C11.9956 3.92215 12.7998 4.00137 13.5307 4.30412C14.2616 4.60687 14.8863 5.11956 15.3259 5.77736C15.7654 6.43515 16 7.20851 16 7.99964C16 9.0605 15.5786 10.0779 14.8284 10.8281C14.0783 11.5782 13.0609 11.9996 12 11.9996Z"
    />
  </svg>
</template>
