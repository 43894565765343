import { render, staticRenderFns } from "./QuickViewApartments.vue?vue&type=template&id=0beb85b7&scoped=true"
import script from "./QuickViewApartments.vue?vue&type=script&lang=js"
export * from "./QuickViewApartments.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0beb85b7",
  null
  
)

export default component.exports