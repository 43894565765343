<script>
export default {
  name: 'BaseChessOne',
  props: {
    fill: {
      type: String,
      default: '#9CA3AF',
    },
    width: {
      type: Number,
      default: 21,
    },
    height: {
      type: Number,
      default: 20,
    },
  },
}
</script>

<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M13.8963 1.66675H16.7179C17.8865 1.66675 18.8333 2.62162 18.8333 3.80005V6.64552C18.8333 7.82395 17.8865 8.77882 16.7179 8.77882H13.8963C12.7277 8.77882 11.7808 7.82395 11.7808 6.64552V3.80005C11.7808 2.62162 12.7277 1.66675 13.8963 1.66675Z"
      :fill="fill"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.28212 1.66675H7.10376C8.27232 1.66675 9.2192 2.62162 9.2192 3.80005V6.64552C9.2192 7.82395 8.27232 8.77882 7.10376 8.77882H4.28212C3.11357 8.77882 2.16669 7.82395 2.16669 6.64552V3.80005C2.16669 2.62162 3.11357 1.66675 4.28212 1.66675ZM4.28212 11.2213H7.10376C8.27232 11.2213 9.2192 12.1762 9.2192 13.3546V16.2001C9.2192 17.3777 8.27232 18.3334 7.10376 18.3334H4.28212C3.11357 18.3334 2.16669 17.3777 2.16669 16.2001V13.3546C2.16669 12.1762 3.11357 11.2213 4.28212 11.2213ZM16.7179 11.2213H13.8963C12.7277 11.2213 11.7808 12.1762 11.7808 13.3546V16.2001C11.7808 17.3777 12.7277 18.3334 13.8963 18.3334H16.7179C17.8865 18.3334 18.8334 17.3777 18.8334 16.2001V13.3546C18.8334 12.1762 17.8865 11.2213 16.7179 11.2213Z"
      :fill="fill"
    />
  </svg>
</template>

<style scoped></style>
