var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-wrap",staticStyle:{"row-gap":"0.5rem"}},[_c('base-search-input',{staticClass:"base-search-input mr-2",attrs:{"placeholder":`${_vm.$t('activity_type_full_name')}`},on:{"trigger-input":_vm.filterBySearchContent}}),_c('div',{staticClass:"d-flex align-items-center"},[_c('base-filter-button',{staticClass:"mr-2 base-filter-button",on:{"click":_vm.openFilterContent}})],1)],1),_c('base-right-modal',{ref:"filter-modal",on:{"reset-fields":_vm.resetFilterFields,"start-filtering":_vm.filterItems}},[_c('div',{staticClass:"filter-modal-content"},[_c('bootstrap-select',{class:{ 'client-type-selection': !_vm.filterFields.actions },attrs:{"options":_vm.actionsTypes,"default-value":_vm.$t('contracts.activity_log.' + _vm.filterFields.actions[0])},on:{"select":(newValue) =>
            (this.filter.action = Object.entries(
              this.filterItemsValues.actions
            ).filter((item) => item[1] === newValue)[0][0])}}),_c('bootstrap-select',{class:{ 'client-type-selection': !_vm.filterFields.types },attrs:{"options":_vm.typesOptions,"default-value":_vm.$t('contracts.activity_log.actions.' + _vm.filterFields.types[0])},on:{"select":(newValue) =>
            (this.filter.type = Object.entries(
              this.filterItemsValues.types
            ).filter((item) => item[1] === newValue)[0][0])}}),(
          _vm.filterFields.users[0] &&
            _vm.filterFields.users[0].first_name &&
            _vm.filterFields.users[0].last_name
        )?_c('bootstrap-select',{class:{ 'client-type-selection': !_vm.filterFields.users },attrs:{"options":_vm.usersOptions,"default-value":_vm.filterFields.users[0].first_name +
            ' ' +
            _vm.filterFields.users[0].last_name},on:{"select":(newValue) =>
            (this.filter.users = this.filterFields.users.filter(
              (user) => user.first_name + ' ' + user.last_name === newValue
            )[0].id)}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }