import { render, staticRenderFns } from "./BuildingStore.vue?vue&type=template&id=38069a06&scoped=true"
import script from "./BuildingStore.vue?vue&type=script&lang=js"
export * from "./BuildingStore.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38069a06",
  null
  
)

export default component.exports