import state from '@/store/modules/CheckoutV2/state'
import getters from '@/store/modules/CheckoutV2/getters'
import mutations from '@/store/modules/CheckoutV2/mutations'
import actions from '@/store/modules/CheckoutV2/actions'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
