import { render, staticRenderFns } from "./EDit.vue?vue&type=template&id=edf40b86&scoped=true"
import script from "./EDit.vue?vue&type=script&lang=js"
export * from "./EDit.vue?vue&type=script&lang=js"
import style0 from "./EDit.vue?vue&type=style&index=0&id=edf40b86&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edf40b86",
  null
  
)

export default component.exports