<script>
import { computed } from "vue";
import { useShowPromo } from "@/composables/useShowPromo";
import { XIcon } from "@/components/ui-components/material-icons";
import { XCircularBackground } from "@/components/ui-components/circular-background";
import ApartmentsPermission from "@/permission/apartments";
import Permission from "@/permission";

export default {
  name: "HidePromoButton",

  components: {
    XIcon,
    XCircularBackground,
  },
  data() {
    return {
      hidePricePermission: Permission.getUserPermission("apartments.hidePrice"),
    };
  },
  setup() {
    const { showPromo, changeHidePromo } = useShowPromo();

    return { changeHidePromo, showPromo };
  },
};
</script>
<template>
  <div>
    <x-circular-background
      v-if="hidePricePermission"
      class="bg-violet-500"
      :class="{ disabled: !showPromo }"
      :padding="0.5"
      style="user-select: none; cursor: pointer; overflow: hidden"
      @click="changeHidePromo"
    >
      <span style="font-size: 24px; color: white" class="d-flex">
        <i class="fas fa-gift" />
      </span>
    </x-circular-background>
  </div>
</template>

<style lang="scss" scoped>
.disabled {
  position: relative;
}

.disabled::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 6px;
  width: 100%;
  height: 100%;
  border-top: 3px solid white; /* Adjust thickness and color as needed */
  transform: rotate(45deg);
  transform-origin: top left;
}
</style>
