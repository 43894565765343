import { render, staticRenderFns } from "./SwitchButtonGroup.vue?vue&type=template&id=2103a3ac&scoped=true"
import script from "./SwitchButtonGroup.vue?vue&type=script&lang=js"
export * from "./SwitchButtonGroup.vue?vue&type=script&lang=js"
import style0 from "./SwitchButtonGroup.vue?vue&type=style&index=0&id=2103a3ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2103a3ac",
  null
  
)

export default component.exports