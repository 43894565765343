var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"secondary__cards"},[_c('div',{staticClass:"secondary__cards__wrapper"},[_c('base-card',{attrs:{"icon":"apartment","title":`${ _vm.$t('objects.title') }`,"multiple":false},scopedSlots:_vm._u([{key:"bottom",fn:function(){return [(_vm.busy)?_c('b-spinner',{staticClass:"secondary__cards__spinner",attrs:{"small":""}}):_c('span',[_vm._v(" "+_vm._s(_vm.data.objects_count)+" ")])]},proxy:true}])}),_c('base-card',{attrs:{"icon":"description","title":`${ _vm.$t('contracts.name') }`,"multiple":false},scopedSlots:_vm._u([{key:"bottom",fn:function(){return [(_vm.busy)?_c('b-spinner',{staticClass:"secondary__cards__spinner",attrs:{"small":""}}):_c('span',[_vm._v(" "+_vm._s(_vm.formatToPrice(_vm.data.contracts_count))+" ")])]},proxy:true}])}),_c('base-card',{staticClass:"widget__grow__2",attrs:{"icon":"price_check","title":`${ _vm.$t('common.receipts') }`,"bottom-left":{
        title: '',
        value:_vm.abbreviateNumber(_vm.data.sales_sum)
      },"bottom-right":{
        title: _vm.$t('plan.title'),
        value: `${ _vm.data.plan_percentage.toFixed(2) }%`
      }}}),_c('base-card',{staticClass:"widget__grow__2",attrs:{"icon":"event_available","title":`${ _vm.$t('common.loose') }`,"bottom-left":{
        title: _vm.$t('common.apartments'),
        value:_vm.data.available_apartments_count
      },"bottom-right":{
        title: _vm.$t('parkings'),
        value:_vm.data.available_parking_count
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }