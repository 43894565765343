import { render, staticRenderFns } from "./BaseCloseIcon.vue?vue&type=template&id=336c2e64"
import script from "./BaseCloseIcon.vue?vue&type=script&lang=js"
export * from "./BaseCloseIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports