var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home__primary__cards"},[_c('base-card',{attrs:{"icon":"location_city","title":`${ _vm.$t('objects.title') }`,"multiple":false},scopedSlots:_vm._u([{key:"bottom",fn:function(){return [_c('span',{staticClass:"mt-4 d-inline-block"},[_vm._v(_vm._s(_vm.data.object_count))])]},proxy:true}])}),_c('base-card',{attrs:{"icon":"group","title":`${ _vm.$t('report.clients') }`,"multiple":false},scopedSlots:_vm._u([{key:"bottom",fn:function(){return [_c('span',{staticClass:"mt-4 d-inline-block"},[_vm._v(_vm._s(_vm.data.client_count))])]},proxy:true}])}),_c('base-card',{staticClass:"base__card__multiple",attrs:{"icon":"door_open","title":_vm.$t('common.apartments'),"bottom-left":{
      title: _vm.$t('common.overall'),
      value: _vm.data.apartments.total
    },"bottom-center":{
      title: _vm.$t('common.sold'),
      value: _vm.data.apartments.sold
    },"bottom-right":{
      title: _vm.$t('common.loose'),
      value: _vm.data.apartments.available
    }}}),_c('base-card',{staticClass:"base__card__multiple",attrs:{"icon":"directions_car","title":_vm.$t('parkings'),"bottom-left":{
      title: _vm.$t('common.overall'),
      value: _vm.data.parking.total
    },"bottom-center":{
      title: _vm.$t('common.sold'),
      value: _vm.data.parking.sold
    },"bottom-right":{
      title: _vm.$t('common.loose'),
      value: _vm.data.parking.available
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }