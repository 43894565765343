import api from '@/services/api'
import { v3ServiceApi } from '@/services/v3/v3.service'

export async function orderApartment(ids, type, name = 'checkout-v2') {
  try {
    const { data } = await api.orders.holdOrder(ids, type)
    if (data) {
      // const objectId = data.orders[0].apartment.object.id
      // await this.$router.push({
      //     name: 'confirm-apartment',
      //     params: {
      //         id: data.uuid,
      //         object: objectId
      //     }
      // })
      // await Promise.all(ids.map(apartment_id => v3ServiceApi.holdLogs.create({ apartment_id })))
      await this.$router.push({
        name,
        params: {
          id: data.uuid,
          object: data.orders[0][type].object.id,
        },
      })
      // await this.$router.push({
      //     name: "checkout",
      //     params: {
      //         id: data.uuid
      //     }
      // })
    }
  } catch (e) {
    this.toastedWithErrorCode(e)
  }
}
