<script>
export default {
  name: 'BaseEditIcon',
  props: {
    fill: {
      type: String,
      default: '#9CA3AF',
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
}
</script>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 18"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.59891 5.19618L12.7323 8.48668C12.832 8.56537 12.849 8.70978 12.7712 8.81038L7.87097 15.101C7.56294 15.4896 7.10898 15.7094 6.62261 15.7175L3.94755 15.75C3.80488 15.7516 3.68004 15.6542 3.64761 15.5147L3.03965 12.9105C2.93426 12.4319 3.03965 11.937 3.34768 11.5557L8.27223 5.23512C8.35167 5.13371 8.4984 5.11586 8.59891 5.19618Z"
      :fill="fill"
    />
    <path
      d="M14.5827 6.52564L13.7858 7.50565C13.7056 7.60543 13.5613 7.62166 13.4616 7.54215C12.4929 6.76983 10.0124 4.7879 9.32413 4.23867C9.22361 4.15754 9.20983 4.01314 9.2909 3.91254L10.0594 2.97228C10.7565 2.088 11.9724 2.00687 12.9533 2.77757L14.0801 3.66186C14.5421 4.01882 14.8502 4.48935 14.9555 4.98423C15.0771 5.52859 14.9474 6.06321 14.5827 6.52564Z"
      :fill="fill"
    />
  </svg>
</template>
