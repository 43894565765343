import { render, staticRenderFns } from "./BootstrapSelect.vue?vue&type=template&id=2c5b06aa&scoped=true"
import script from "./BootstrapSelect.vue?vue&type=script&lang=js"
export * from "./BootstrapSelect.vue?vue&type=script&lang=js"
import style0 from "./BootstrapSelect.vue?vue&type=style&index=0&id=2c5b06aa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c5b06aa",
  null
  
)

export default component.exports