<script>
// import BaseCircleWrapper from "@/components/Reusable/BaseCircleWrapper";
// import BaseQuestionsIcon from "@/components/icons/BaseQuestionsIcon";
// import BaseNotificationsIcon from "@/components/icons/BaseNotificationsIcon";
import BaseAvatar from "@/components/Reusable/BaseAvatar";
import { mapGetters } from "vuex";
import { v3ServiceApi } from "@/services/v3/v3.service";
import { XCircularBackground } from "@/components/ui-components/circular-background";
import { formatDateWithDot, formatToPrice } from "@/util/reusable";

export default {
  name: "AppHeader",
  components: {
    BaseAvatar,
    XCircularBackground,
    // BaseCircleWrapper,
    // BaseQuestionsIcon,
    // BaseNotificationsIcon
  },
  data() {
    return {
      currency: null,
    };
  },
  computed: {
    ...mapGetters({
      pms: "getPermission",
      me: "getMe",
    }),
    userCollapse() {
      // eslint-disable-next-line no-prototype-builtins
      return this.$slots.hasOwnProperty("header-actions");
    },
    getFullName() {
      if (this.me?.user) {
        const { firstName, lastName } = this.me.user;
        if (firstName !== "" && lastName !== "") {
          return `${firstName} ${lastName}`;
        }
      }
      return "";
    },
    getNameSnippet() {
      if (this.me?.user) {
        const { firstName, lastName } = this.me.user;
        if (firstName !== "" && lastName !== "") {
          return firstName[0] + lastName[0];
        }
      }
      return "";
    },
    getUserAvatarUrl() {
      if (this.me?.user?.avatar) {
        return this.me.user.avatar;
      }
      return "";
    },
    getRole() {
      if (this.me?.role?.name) {
        if (localStorage.locale)
          return this.me?.role?.name[localStorage.locale];
        return this.me?.role?.name.ru;
      }
      return "no-role";
    },
  },
  mounted() {
    v3ServiceApi.currency
      .getCurrent()
      .then((res) => (this.currency = res.data));
  },
  methods: {
    formatPrice: (v) => formatToPrice(v),
  },
};
</script>

<template>
  <header class="app-header">
    <div class="header-left">
      <!--  TODO:SLOT HEADER_BREADCRUMB     -->
      <slot name="header-breadcrumb" />
      <h3 class="app-header-title">
        <!--  TODO:SLOT HEADER_TITLE      -->
        <slot name="header-title" />
      </h3>
      <slot name="header-status" />
    </div>
    <div class="header-right">
      <div class="currency-info" v-if="currency">
        <x-circular-background
          class="bg-violet-500"
          padding="0.5"
          style="
            padding-left: 1rem;
            padding-right: 1rem;
            user-select: none;
            cursor: pointer;
            overflow: hidden;
            color: white;
          "
        >
          $
        </x-circular-background>
        <div class="currency-wrap">
          <div class="info-row">
            <span>{{ $t("refreshed") }}</span>
            <span>{{ currency.updated_at.split("T")[1].slice(0, 5) }}</span>
          </div>
          <div class="info-row">
            <span>$1</span>
            <span>{{ formatPrice(currency.usd) }}</span>
          </div>
          <div class="info-row">
            <span>€1</span>
            <span>{{ formatPrice(currency.euro) }}</span>
          </div>
        </div>
      </div>

      <!--  TODO:SLOT HEADER_ACTIONS      -->
      <slot name="header-actions" />

      <BaseAvatar
        :class="{ collapsed: userCollapse }"
        :name-snippet="getNameSnippet"
        :avatar="getUserAvatarUrl"
      >
        <template #full_name>
          {{ getFullName }}
        </template>
        <template #role>
          {{ getRole }}
        </template>
      </BaseAvatar>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //margin-top: 1rem;
  margin-bottom: 2rem;

  .currency-info {
    position: relative;

    & .currency-wrap {
      position: absolute;
      top: 0;
      right: 0;
      display: none;
      background-color: var(--gray-100);
      padding: 10px;
      border-radius: 20px;
      box-shadow: 0px 0px 4px var(--gray-600);
      z-index: 10;
    }
    &:hover .currency-wrap {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 8px;
      & .info-row {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  }

  .header-left {
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
  }

  .header-right {
    display: flex;
    align-items: center;
    justify-self: flex-end;
    column-gap: 0.5rem;
  }

  &-title {
    font-family: CraftworkSans, serif;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 42px;
    color: var(--gray-600);
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
