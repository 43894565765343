import { ref } from 'vue'

const showPromo = ref(!!+localStorage.getItem('showPromo'))
export function useShowPromo() {
  const changeHidePromo = function () {
    showPromo.value = !showPromo.value
    localStorage.setItem('showPromo', +showPromo.value)
  }

  return {
    showPromo,
    changeHidePromo,
  }
}
