export default {
  setNotify(state, xNotify) {
    if (xNotify && Object.keys(xNotify).length) {
      let defaultProperty = {}
      if (xNotify && xNotify.type === 'success') {
        defaultProperty = {
          visible: true,
          type: 'success',
          message: 'successfully',
          duration: 2000,
        }
      } else if (xNotify && xNotify.type === 'warning') {
        defaultProperty = {
          visible: true,
          type: 'warning',
          message: 'warning',
          duration: 2000,
        }
      } else {
        defaultProperty = {
          visible: true,
          type: 'error',
          message: 'error',
          duration: 2000,
        }
      }
      state.xNotify = { ...defaultProperty, ...xNotify }
    } else {
      state.xNotify = xNotify
    }
  },
  finishNotify(state, visible) {
    state.xNotify.visible = visible
  },
}
